import { useQuery } from "@tanstack/react-query";
import axios from "axios";

// Fetch the client's rows from the database
export const fetchClientRows = async (clientId) => {
  const rows = await axios.get(`/api/client-rows/${clientId}/`, {
    withCredentials: true,
  });

  return rows.data;
};

// Custom hook to fetch the client's rows
export default function useClientRows(clientId, userId) {
  return useQuery({
    queryKey: ["clientRows", clientId],
    queryFn: () => fetchClientRows(clientId),
    enabled: !!clientId && !!userId,
    refetchInterval: 120000,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });
}
