import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import RuleIcon from "@mui/icons-material/Rule";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import SyncIcon from "@mui/icons-material/Sync";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import Popover from "@mui/material/Popover";
import Tooltip from "@mui/material/Tooltip";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { setProjectID } from "../../../../Redux/Actions/actions";
import "../../../Tooltip/styles/tooltip.css";
import { DashboardContext } from "../../DashboardContext/DashboardContextProvider";
import * as defaultStyles from "../../styles/main-datagrid.module.css";
import "../styles/client-data-grid.css";

// | TOOLBAR |
export const CustomToolbar = ({
  value,
  handleSearch,
  filterButtonRef,
  handleClearInput,
}) => {
  return (
    <div className={"mainToolbarContainer"}>
      <GridToolbarContainer className={"toolbarLeft"} id="toolbar-left">
        <GridToolbarFilterButton
          className={"toolbarFilter"}
          id="toolbar-filter-button"
          ref={filterButtonRef}
        />
      </GridToolbarContainer>
      <div className="toolbarRight clientToolbarRight">
        <FormControl
          variant="outlined"
          id={"search-form-control"}
          classes={{ root: "search-form-control-root" }}
        >
          <OutlinedInput
            type="text"
            id="search-field"
            label="Search Table"
            value={value}
            onChange={handleSearch}
            classes={{
              root: "search-field-root",
              notchedOutline: "search-field-notchedOutline",
              input: "search-field-input",
            }}
            startAdornment={
              <SearchIcon className="search-icon" aria-hidden={true} />
            }
            endAdornment={
              <IconButton
                id="clear-search-btn"
                onClick={handleClearInput}
                classes={{ root: "clear-search-btn-root" }}
              >
                <CloseIcon className="clear-search-icon" />
              </IconButton>
            }
          />
        </FormControl>
      </div>
    </div>
  );
};

// | REQUEST STATUS CELL |
export function RequestStatusCell({ params, apiRef }) {
  const { id, value, field } = params;
  const { setOpenSelect } = useContext(DashboardContext);

  // make cell editable on click of chip
  const handleChipClick = (e) => {
    apiRef.current.startCellEditMode({ id, field });
    setOpenSelect(true);
  };

  if (value === "I") {
    return (
      <Tooltip
        title={`This request has not been submitted and currently is available to edit. Click the 'EDIT' button to begin editing this projects services questionnaire.`}
        id="requestStatus-tooltip"
        classes={{
          tooltip: "dashboard-tooltip",
          popper: "dashboard-tooltip-popper",
          arrow: "dashboard-arrow",
        }}
        placement="right"
      >
        <Chip
          key={`incomplete-${id}`}
          icon={<RuleIcon className={"incompleteCheck"} />}
          label={"Editable"}
          id={"incompleteChip"}
          classes={{
            label: "chipStatusLabel",
            root: "incompleteChipRoot",
          }}
        />
      </Tooltip>
    );
  } else if (value === "P") {
    return (
      <Tooltip
        title={`This projects services questionnaire has been submitted (${params.row.submittedAt}) and is currently awaiting verification/approval from an Argus team member. You can view a read-only version of the services questionnaire form by clicking the 'VIEW' button. A downloadable PDF copy of the services questionnaire will be available when approved`}
        id="requestStatus-tooltip"
        classes={{
          tooltip: "dashboard-tooltip",
          popper: "dashboard-tooltip-popper",
          arrow: "dashboard-arrow",
        }}
        placement="right"
      >
        <Chip
          key={`pending-${id}`}
          icon={<AccessTimeFilledIcon className={"pendingWheel"} />}
          label={"Pending"}
          id={"pendingChip"}
          classes={{
            label: "chipStatusLabel",
            root: "pendingChipRoot",
          }}
        />
      </Tooltip>
    );
  } else if (value === "IP") {
    return (
      <Tooltip
        title={`This projects questionnire is currently being edited by another user. Click the 'VIEW' button to view the a read-only version of the services questionnaire form, or wait until the status is 'Editable' to begin editing the form`}
        id="requestStatus-tooltip"
        classes={{
          tooltip: "dashboard-tooltip",
          popper: "dashboard-tooltip-popper",
          arrow: "dashboard-arrow",
        }}
        placement="right"
      >
        <Chip
          key={`inProgress-${id}`}
          icon={<SyncIcon className={"progressCircle"} />}
          label={"In Progress"}
          id={"inProgressChip"}
          classes={{
            label: "chipStatusLabel",
            root: "inProgressChipRoot",
          }}
        />
      </Tooltip>
    );
  } else if (value === "RV") {
    return (
      <Tooltip
        title="An Argus team member has marked information in this form as invalid and may require revisions. You can update & resubmit this form by clicking the 'Revise' button"
        id="requestStatus-tooltip"
        classes={{
          tooltip: "dashboard-tooltip",
          popper: "dashboard-tooltip-popper",
          arrow: "dashboard-arrow",
        }}
        placement="right"
      >
        <Chip
          key={`revision-${id}`}
          icon={<PriorityHighIcon className={"reviseBox"} />}
          label={"Revisions"}
          id={"reviseChip"}
          classes={{
            root: "reviseChipRoot",
            label: "chipStatusLabel",
          }}
        />
      </Tooltip>
    );
  } else if (value === "C") {
    return (
      <Tooltip
        title={`This projects questionnaire has been approved by an Argus team member (${params.row.completedAt}). You can view/download a PDF copy of the services questionniare by clicking the 'DOWNLOAD' button. This projects request may be archived by selecting the archive/bookmark icon in the 'Archive' column.`}
        id="requestStatus-tooltip"
        classes={{
          tooltip: "dashboard-tooltip",
          popper: "dashboard-tooltip-popper",
          arrow: "dashboard-arrow",
        }}
        placement="right"
      >
        <Chip
          icon={<CheckIcon className={"completedCheck"} />}
          label={params.row.archived ? "Archived" : "Completed"}
          id={"completedChip"}
          className={params.row.archived ? "archived-chip" : ""}
          // clickable={true}
          // onClick={handleChipClick}
          classes={{
            root: "completedChipRoot",
            label: "chipStatusLabel",
          }}
        />
      </Tooltip>
    );
  }
}

// | SWPPP SERVICES POPOVER |
export function SwpppServicesPopover({ params }) {
  const {
    swpppAnchor,
    setSwpppAnchor,
    openSwppp,
    setOpenSwppp,
    setCurrSwpppRow,
  } = useContext(DashboardContext);

  // useEffect(() => console.log(swpppAnchor), [swpppAnchor]);
  return (
    <Popover
      id={"swpppPopover"}
      key={params.id}
      open={openSwppp}
      anchorEl={swpppAnchor}
      onClose={() => {
        setSwpppAnchor(null);
        setCurrSwpppRow(null);
        setOpenSwppp(false);
      }}
      slotProps={{
        paper: {
          className: "popoverPaper",
          elevation: 0,
        },
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: -75 }}
    >
      <>
        <h4
          id={"swpppPopoverHeading"}
        >{`Requested SWPPP Services for ${params.row.name}`}</h4>
        {/* <hr id={"popoverHr"} /> */}
        {params.row.swpppServices.length > 1 ? (
          <ol className={"swpppList"}>
            {params.row.swpppServices.map((service, index) => {
              return (
                <li
                  key={index}
                  className={"swpppListItem"}
                  style={{ color: "red" }}
                >
                  {service}
                </li>
              );
            })}
          </ol>
        ) : (
          <p className={"soloListItem"}>{`- ${params.row.swpppServices[0]}`}</p>
        )}
      </>
    </Popover>
  );
}

// | DUST SERVICES POPOVER |
export function DustServicesPopover({ params }) {
  const { dustAnchor, setDustAnchor, openDust, setOpenDust, setCurrDustRow } =
    useContext(DashboardContext);
  return (
    <Popover
      id={"dustPopover"}
      key={params.id}
      open={openDust}
      anchorEl={dustAnchor}
      onClose={() => {
        setDustAnchor(null);
        setCurrDustRow(null);
        setOpenDust(false);
      }}
      slotProps={{
        paper: {
          className: `${"popoverPaper"}`,
          elevation: 0,
        },
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: -55 }}
    >
      <>
        <h4
          id={"dustPopoverHeading"}
        >{`Requested Dust Control Services for ${params.row.name}`}</h4>
        {/* <hr id={"popoverHr"} /> */}
        {params.row.dustServices.length > 1 ? (
          <ol className={"dustList"}>
            {params.row.dustServices.map((service, index) => {
              return (
                <li key={index} className={"dustListItem"}>
                  {service}
                </li>
              );
            })}
          </ol>
        ) : (
          <p className={"soloListItem"}>{`- ${params.row.dustServices[0]}`}</p>
        )}
      </>
    </Popover>
  );
}

// | SERVICES POPOVER |
export function ServicesPopover({ params }) {
  const {
    servicesAnchor,
    setServicesAnchor,
    openServices,
    setOpenServices,
    setCurrServicesRow,
  } = useContext(DashboardContext);
  return (
    <Popover
      id={"servicesPopover"}
      key={params.id}
      open={openServices}
      anchorEl={servicesAnchor}
      onClose={() => {
        setServicesAnchor(null);
        setCurrServicesRow(null);
        setOpenServices(false);
      }}
      slotProps={{
        paper: {
          className: "popoverPaper",
          elevation: 0,
        },
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: -55 }}
    >
      <div className={"servicesContainer"}>
        <>
          <h4 className={"servicesPopoverHeading"}>Requested SWPPP Services</h4>
          {/* <hr id={"popoverHr"} /> */}
          {params.row.services.swppp.length >= 1 ? (
            <ol className={"servicesList"}>
              {params.row.services.swppp.map((service, index) => {
                return (
                  <li key={index} className={"servicesListItem"}>
                    {service}
                  </li>
                );
              })}
            </ol>
          ) : (
            <p className={"soloListItem"}>{"- No Services Selected"}</p>
          )}
        </>
        <>
          <h4 className={"servicesPopoverHeading"}>
            Requested Dust Control Services
          </h4>
          {/* <hr id={"popoverHr"} /> */}
          {params.row.services.dust.length > 1 ? (
            <ol className={"servicesList"}>
              {params.row.services.dust.map((service, index) => {
                return (
                  <li key={index} className={"servicesListItem"}>
                    {service}
                  </li>
                );
              })}
            </ol>
          ) : (
            <p className={"soloListItem"}>{`- No Services Selected`}</p>
          )}
        </>
      </div>
    </Popover>
  );
}

// | NO ROWS OVERLAY |
export function NoRowsOverlay() {
  const dispatch = useDispatch();
  const { navigate, clientId, isAdmin } = useContext(DashboardContext);

  const handleCreateRequest = (e) => {
    dispatch(setProjectID(null));
    if (isAdmin) {
      navigate("dashboard/form");
    } else {
      navigate(`/dashboard/form/${clientId}/`);
    }
  };
  return (
    <div className={defaultStyles.noRowsOverlay}>
      <p className={defaultStyles.noRowsOverlayText}>
        {isAdmin
          ? "No requests have been made. Create & invite a user or start a request for a client by clicking the buttons below"
          : "No requests have been made. Click the button below to create your first request"}
      </p>
      <div className={defaultStyles.noRowsOverlayButtonContainer}>
        {isAdmin && (
          <Button
            variant="contained"
            className={defaultStyles.noRowsOverlayButton}
            id={defaultStyles.inviteUserButton}
            onClick={() => navigate("/dashboard/admin/invite-new-user")}
          >
            <SendIcon
              className={defaultStyles.noRowsOverlayButtonIcon}
              id={defaultStyles.sendIcon}
            />
            <span className={defaultStyles.noRowsOverlayButtonText}>
              Invite User
            </span>
          </Button>
        )}
        <Button
          variant="contained"
          className={defaultStyles.noRowsOverlayButton}
          id={defaultStyles.createRequestButton}
          onClick={handleCreateRequest}
        >
          <AddIcon className={defaultStyles.noRowsOverlayButtonIcon} />
          <span className={defaultStyles.noRowsOverlayButtonText}>
            New Request
          </span>
        </Button>
      </div>
    </div>
  );
}
